<template>
    <div class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
        <div uk-spinner="ratio: 2"></div>
    </div>
</template>

<script>
export default {
    name: 'LoaderPage'
};
</script>
